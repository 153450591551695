
    <template>
      <section class="content element-doc">
        <h2>内置过渡动画</h2>
<p>Element 内应用在部分组件的过渡动画，你也可以直接使用。在使用之前请阅读 <a href="https://cn.vuejs.org/v2/api/#transition">transition 组件文档</a> 。</p>
<h3>fade 淡入淡出</h3>
<demo-block>
        <div><p>提供 <code>el-fade-in-linear</code> 和 <code>el-fade-in</code> 两种效果。</p>
</div>
        <template #source><element-demo0 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;div&gt;
  &lt;el-button @click=&quot;show = !show&quot;&gt;Click Me&lt;/el-button&gt;

  &lt;div style=&quot;display: flex; margin-top: 20px; height: 100px;&quot;&gt;
    &lt;transition name=&quot;el-fade-in-linear&quot;&gt;
      &lt;div v-show=&quot;show&quot; class=&quot;transition-box&quot;&gt;.el-fade-in-linear&lt;/div&gt;
    &lt;/transition&gt;
    &lt;transition name=&quot;el-fade-in&quot;&gt;
      &lt;div v-show=&quot;show&quot; class=&quot;transition-box&quot;&gt;.el-fade-in&lt;/div&gt;
    &lt;/transition&gt;
  &lt;/div&gt;
&lt;/div&gt;

&lt;script&gt;
  export default {
    data: () =&gt; ({
      show: true
    })
  }
&lt;/script&gt;

&lt;style&gt;
  .transition-box {
    margin-bottom: 10px;
    width: 200px;
    height: 100px;
    border-radius: 4px;
    background-color: #409eff;
    text-align: center;
    color: #fff;
    padding: 40px 20px;
    box-sizing: border-box;
    margin-right: 20px;
  }
&lt;/style&gt;
</code></pre></template></demo-block><h3>zoom 缩放</h3>
<demo-block>
        <div><p>提供 <code>el-zoom-in-center</code>，<code>el-zoom-in-top</code> 和 <code>el-zoom-in-bottom</code> 三种效果。</p>
</div>
        <template #source><element-demo1 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;div&gt;
  &lt;el-button @click=&quot;show2 = !show2&quot;&gt;Click Me&lt;/el-button&gt;

  &lt;div style=&quot;display: flex; margin-top: 20px; height: 100px;&quot;&gt;
    &lt;transition name=&quot;el-zoom-in-center&quot;&gt;
      &lt;div v-show=&quot;show2&quot; class=&quot;transition-box&quot;&gt;.el-zoom-in-center&lt;/div&gt;
    &lt;/transition&gt;

    &lt;transition name=&quot;el-zoom-in-top&quot;&gt;
      &lt;div v-show=&quot;show2&quot; class=&quot;transition-box&quot;&gt;.el-zoom-in-top&lt;/div&gt;
    &lt;/transition&gt;

    &lt;transition name=&quot;el-zoom-in-bottom&quot;&gt;
      &lt;div v-show=&quot;show2&quot; class=&quot;transition-box&quot;&gt;.el-zoom-in-bottom&lt;/div&gt;
    &lt;/transition&gt;
  &lt;/div&gt;
&lt;/div&gt;

&lt;script&gt;
  export default {
    data: () =&gt; ({
      show2: true
    })
  }
&lt;/script&gt;

&lt;style&gt;
  .transition-box {
    margin-bottom: 10px;
    width: 200px;
    height: 100px;
    border-radius: 4px;
    background-color: #409eff;
    text-align: center;
    color: #fff;
    padding: 40px 20px;
    box-sizing: border-box;
    margin-right: 20px;
  }
&lt;/style&gt;
</code></pre></template></demo-block><h3>collapse 展开折叠</h3>
<p>使用 <code>el-collapse-transition</code> 组件实现折叠展开效果。</p>
<demo-block>
        
        <template #source><element-demo2 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;div&gt;
  &lt;el-button @click=&quot;show3 = !show3&quot;&gt;Click Me&lt;/el-button&gt;

  &lt;div style=&quot;margin-top: 20px; height: 200px;&quot;&gt;
    &lt;el-collapse-transition&gt;
      &lt;div v-show=&quot;show3&quot;&gt;
        &lt;div class=&quot;transition-box&quot;&gt;el-collapse-transition&lt;/div&gt;
        &lt;div class=&quot;transition-box&quot;&gt;el-collapse-transition&lt;/div&gt;
      &lt;/div&gt;
    &lt;/el-collapse-transition&gt;
  &lt;/div&gt;
&lt;/div&gt;

&lt;script&gt;
  export default {
    data: () =&gt; ({
      show3: true
    })
  }
&lt;/script&gt;

&lt;style&gt;
  .transition-box {
    margin-bottom: 10px;
    width: 200px;
    height: 100px;
    border-radius: 4px;
    background-color: #409eff;
    text-align: center;
    color: #fff;
    padding: 40px 20px;
    box-sizing: border-box;
    margin-right: 20px;
  }
&lt;/style&gt;
</code></pre></template></demo-block><h3>按需引入</h3>
<pre><code class="hljs language-js"><span class="hljs-comment">// fade/zoom 等</span>
<span class="hljs-keyword">import</span> <span class="hljs-string">&#x27;element3/lib/theme-chalk/base.css&#x27;</span>
<span class="hljs-comment">// collapse 展开折叠</span>
<span class="hljs-keyword">import</span> CollapseTransition <span class="hljs-keyword">from</span> <span class="hljs-string">&#x27;element3/lib/transitions/collapse-transition&#x27;</span>
<span class="hljs-keyword">import</span> Vue <span class="hljs-keyword">from</span> <span class="hljs-string">&#x27;vue&#x27;</span>

Vue.component(CollapseTransition.name, CollapseTransition)
</code></pre>

      </section>
    </template>
    <script>
      import hljs from 'highlight.js'
      import * as Vue from "vue"
      export default {
        name: 'component-doc',
        components: {
          "element-demo0": (function() {
    
    const { createTextVNode: _createTextVNode, resolveComponent: _resolveComponent, withCtx: _withCtx, createVNode: _createVNode, vShow: _vShow, withDirectives: _withDirectives, Transition: _Transition, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_button = _resolveComponent("el-button")

  return (_openBlock(), _createBlock("div", null, [
    _createVNode(_component_el_button, {
      onClick: $event => (_ctx.show = !_ctx.show)
    }, {
      default: _withCtx(() => [
        _createTextVNode("Click Me")
      ]),
      _: 1
    }, 8, ["onClick"]),
    _createVNode("div", { style: {"display":"flex","margin-top":"20px","height":"100px"} }, [
      _createVNode(_Transition, { name: "el-fade-in-linear" }, {
        default: _withCtx(() => [
          _withDirectives(_createVNode("div", { class: "transition-box" }, ".el-fade-in-linear", 512), [
            [_vShow, _ctx.show]
          ])
        ]),
        _: 1
      }),
      _createVNode(_Transition, { name: "el-fade-in" }, {
        default: _withCtx(() => [
          _withDirectives(_createVNode("div", { class: "transition-box" }, ".el-fade-in", 512), [
            [_vShow, _ctx.show]
          ])
        ]),
        _: 1
      })
    ])
  ]))
}
  
    const democomponentExport = {
    data: () => ({
      show: true
    })
  }
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo1": (function() {
    
    const { createTextVNode: _createTextVNode, resolveComponent: _resolveComponent, withCtx: _withCtx, createVNode: _createVNode, vShow: _vShow, withDirectives: _withDirectives, Transition: _Transition, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_button = _resolveComponent("el-button")

  return (_openBlock(), _createBlock("div", null, [
    _createVNode(_component_el_button, {
      onClick: $event => (_ctx.show2 = !_ctx.show2)
    }, {
      default: _withCtx(() => [
        _createTextVNode("Click Me")
      ]),
      _: 1
    }, 8, ["onClick"]),
    _createVNode("div", { style: {"display":"flex","margin-top":"20px","height":"100px"} }, [
      _createVNode(_Transition, { name: "el-zoom-in-center" }, {
        default: _withCtx(() => [
          _withDirectives(_createVNode("div", { class: "transition-box" }, ".el-zoom-in-center", 512), [
            [_vShow, _ctx.show2]
          ])
        ]),
        _: 1
      }),
      _createVNode(_Transition, { name: "el-zoom-in-top" }, {
        default: _withCtx(() => [
          _withDirectives(_createVNode("div", { class: "transition-box" }, ".el-zoom-in-top", 512), [
            [_vShow, _ctx.show2]
          ])
        ]),
        _: 1
      }),
      _createVNode(_Transition, { name: "el-zoom-in-bottom" }, {
        default: _withCtx(() => [
          _withDirectives(_createVNode("div", { class: "transition-box" }, ".el-zoom-in-bottom", 512), [
            [_vShow, _ctx.show2]
          ])
        ]),
        _: 1
      })
    ])
  ]))
}
  
    const democomponentExport = {
    data: () => ({
      show2: true
    })
  }
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo2": (function() {
    
    const { createTextVNode: _createTextVNode, resolveComponent: _resolveComponent, withCtx: _withCtx, createVNode: _createVNode, vShow: _vShow, withDirectives: _withDirectives, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_button = _resolveComponent("el-button")
  const _component_el_collapse_transition = _resolveComponent("el-collapse-transition")

  return (_openBlock(), _createBlock("div", null, [
    _createVNode(_component_el_button, {
      onClick: $event => (_ctx.show3 = !_ctx.show3)
    }, {
      default: _withCtx(() => [
        _createTextVNode("Click Me")
      ]),
      _: 1
    }, 8, ["onClick"]),
    _createVNode("div", { style: {"margin-top":"20px","height":"200px"} }, [
      _createVNode(_component_el_collapse_transition, null, {
        default: _withCtx(() => [
          _withDirectives(_createVNode("div", null, [
            _createVNode("div", { class: "transition-box" }, "el-collapse-transition"),
            _createVNode("div", { class: "transition-box" }, "el-collapse-transition")
          ], 512), [
            [_vShow, _ctx.show3]
          ])
        ]),
        _: 1
      })
    ])
  ]))
}
  
    const democomponentExport = {
    data: () => ({
      show3: true
    })
  }
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),
        }
      }
    </script>
  